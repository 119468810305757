

@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Cairo+Play:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500;600;700;800&display=swap');


*{
  transition: all 0.3s ease-out;
}

.font-nav{
  font-family: 'Cairo', sans-serif;
}
body {
  margin: 0;
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slick-center {
  margin-top: 200px ip !important;
}


.map{
  background: rgba(74, 31, 0, 0.2);    
}


.hamburger {
  display: none;
}
.dropdown-menu{
  visibility: visible;
}
.dropdown-mobile{
  display: none;
}


.text-shadow2{
  text-shadow: 1px 2px 2px #000;
  text-transform: uppercase;
  font-weight:700;
}